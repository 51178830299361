import * as React from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import customerService from 'services/customerService';
import { Size } from '@spglobal/koi-helpers';
import UserContext from 'context/UserContext';
import useGetPermission from 'hooks/useGetPermission';
import { ACTION, RESCORE, MRP } from 'utils/constants';
import { useState } from 'react';
import { ARROW_UP, BARS } from '@spglobal/koi-icons';
import { IconButton, NotificationType, useNotification } from '@spglobal/react-components';
import uploadAssetAPI from 'api/upload-assets';
import config from 'config';
import DataService from 'service/data.service';
import { getRole } from 'utils/user';
import CustomHeader from '../Header/CustomHeader';
import BreadcrumbTrail from '../BreadcrumbTrail';
import DataTableGrid from '../DataTable/DataTableGrid/DataTableGrid';
import { useApiGet } from '../../hooks/useApiGet';


const CustomerList = () => {
    const [customers, setCustomers] = React.useState([]);
    const [filteredCustomers, setFilteredCustomers] = React.useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [rescoreDisabled, setRescoreDisabled] = React.useState(false);
    const [mrpDisabled, setMRPDisabled] = React.useState(false);
    const { data: Mrp_menu } = useApiGet('api/appConfig', { keys: 'Mrp_menu' });
    const Mrp_menu_Disabled = Mrp_menu?.results?.find((i) => i.key === 'Mrp_menu_Disabled').value==='true';

    const history = useHistory();
    const { addNotification } = useNotification();
    const { user: userContextDetails, isLoggedIn } = React.useContext(UserContext);
    const role = getRole(
        userContextDetails && userContextDetails.SPGGroups ? userContextDetails.SPGGroups : [],
    );
    const service = new DataService();

    const getCustomers = () => {
        async function fetchData() {
            try {
                const params = {
                    allcustomer: 'True',
                };
                const resData = await customerService.getAll(params);

                if (resData && resData.results) {
                    resData.results.forEach((element) => {
                        element.asset_count = element.asset_count ? element.asset_count : 'NA';
                    });
                }

                setCustomers(resData && resData.results ? resData.results : []);
                setFilteredCustomers(resData && resData.results ? resData.results : []);
                setDataLoaded(true);
            } catch (e) {
                setDataLoaded(true);
                console.log('error :', e);
            }
        }

        fetchData();
    };

    const handleTableSearch = (searchVal) => {
        if (searchVal?.length === 0) {
            return setFilteredCustomers(customers);
        }
        const filteredRows = customers.filter((row) =>
            row?.name.toLowerCase().includes(searchVal.toLowerCase()),
        );
        return setFilteredCustomers(filteredRows);
    };

    React.useEffect(() => {
        getCustomers();
    }, []);

    const userAccess =
        userContextDetails && Object.keys(userContextDetails).length > 0
            ? useGetPermission(userContextDetails, isLoggedIn, 'Customers')
            : '';

    const actions =
        userAccess?.action === ACTION.ACTION_READ
            ? [ACTION.ACTION_READ]
            : userAccess?.action === ACTION.ACTION_WRITE
            ? [ACTION.ACTION_READ, ACTION.ACTION_EDIT]
            : [];

    const capitalizeFirstLater = (str) => {
        if (str) return str.charAt(0).toUpperCase() + str.slice(1);
        return str;
    };

    const customerTypeCellRenderer = ({ data }) => capitalizeFirstLater(data?.customer_type);

    const subStartDateCellRenderer = ({ data }) =>
        data?.subscription_start_date
            ? new Date(data?.subscription_start_date).toLocaleDateString()
            : 'NA';

    const subEndDateCellRenderer = ({ data }) =>
        data?.subscription_end_date
            ? new Date(data?.subscription_end_date).toLocaleDateString()
            : 'NA';

    const dateColFilterComparator = (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = dayjs(cellValue).format('MM/DD/YYYY');

        if (dateAsString === null) {
            return 0;
        }

        const dateParts = dateAsString.split('/');
        const day = Number(dateParts[1]);
        const month = Number(dateParts[0]) - 1;
        const year = Number(dateParts[2]);
        const cellDate = new Date(year, month, day);

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }

        return 0;
    };

    const rescoreAssets = async (params, action_type) => {
        setRescoreDisabled(true);

        let msg = 'Asset Rescoring has started Successfully';
        let type = NotificationType.SUCCESS;
        try {
            let reqData = {};
            const token = service.getUserDetails();

            const userData = {};
            if (token) {
                const {
                    claims: { email, name, SPGGroups },
                } = token;
                userData.userEmail = email;
                userData.userName = name;
                userData.userRole = SPGGroups;
            }
            if (config.devEnv === 'LOCAL') {
                reqData = {
                    customer_name: params.data.name.replace(/[/ ]/g, '_'),
                    file_name: params.data.name.replace(/[/ ]/g, '_'),
                    customer_id: params.data.id,
                    user_role: userData.userRole.toString(),
                    user_name: userData.userName,
                    email: userData.userEmail,
                    internal: true,
                    action_type,
                };
            } else {
                reqData = {
                    customer_name: params.data.name.replace(/[/ ]/g, '_'),
                    file_name: params.data.name.replace(/[/ ]/g, '_'),
                    customer_id: params.data.id,
                    user_role: userData.userRole.toString(),
                    user_name: userData.userName,
                    email: userData.userEmail,
                    internal: true,
                    action_type,
                };
            }

            await uploadAssetAPI.getUploadPreSignedURL(reqData);
        } catch (error) {
            msg =
                error?.response?.status === 401
                    ? `Error: ${error?.response?.data}`
                    : 'Error: Asset Rescoring failed.';
            type = NotificationType.ERROR;
        }

        setRescoreDisabled(false);
        addNotification(msg, type);
    };


    const initiateMRPCalculation = async (params, action_type) => {
      setMRPDisabled(true);

      let msg;

      let type = NotificationType.SUCCESS;
      try {
          let reqData = {};
          const token = service.getUserDetails();

          const userData = {};
          if (token) {
              const {
                  claims: { email, name, SPGGroups },
              } = token;
              userData.userEmail = email;
              userData.userName = name;
              userData.userRole = SPGGroups;
          }
          if (config.devEnv === 'LOCAL') {
              reqData = {
                  customer_name: params.data.name.replace(/[/ ]/g, '_'),
                  file_name: params.data.name.replace(/[/ ]/g, '_'),
                  customer_id: params.data.id,
                  user_role: userData.userRole.toString(),
                  user_name: userData.userName,
                  email: userData.userEmail,
                  internal: true,
                  action_type,
              };
          } else {
              reqData = {
                  customer_name: params.data.name.replace(/[/ ]/g, '_'),
                  file_name: params.data.name.replace(/[/ ]/g, '_'),
                  customer_id: params.data.id,
                  user_role: userData.userRole.toString(),
                  user_name: userData.userName,
                  email: userData.userEmail,
                  internal: true,
                  action_type,
              };
          }

         await uploadAssetAPI.getUploadPreSignedURL(reqData);

         msg = 'Multiple Return Period Calculation has started successfully';

      } catch (error) { 
        msg =
            error?.response?.status === 401
                ? `Error: ${error?.response?.data}`
                : error?.response?.status === 403
                ? 'The portfolio is being calculated for Multiple Return Periods. Please wait a moment while we finalize the process. Thank you for your patience!'
                : 'The calculation failed. Please open a ticket on ServiceNow with the customer name and customer ID.';
        type = NotificationType.ERROR;
      }

      addNotification(msg, type);
      setMRPDisabled(false);
  };

  const handleActionsClick = (event, actionType, params) => {
    event.stopPropagation();
    if (actionType === RESCORE) {
        rescoreAssets(params, actionType);
    } else if (actionType === MRP) {
        initiateMRPCalculation(params, actionType);
    } 
};

    const actionsCellRenderer = (actionsCellProps) => (
        <div className="spg-d-flex spg-align-center">
            {role && role === 'GRP_CLIM_DEVELOPER' && (
                <IconButton
                    aria-label="rescore-assets"
                    disabled={rescoreDisabled}
                    size={Size.SMALL}
                    icon={ARROW_UP}
                    onClick={(e) => handleActionsClick(e, RESCORE, actionsCellProps)}
                    title="Rescore assets"
                />
            )}
            {!Mrp_menu_Disabled && (
                <IconButton
                    aria-label="mrp-assets-upload"
                    disabled={mrpDisabled}
                    size={Size.SMALL}
                    icon={BARS}
                    onClick={(e) => handleActionsClick(e, MRP, actionsCellProps)}
                    title="Multiple Return Period Calculation"
                />
            )}

        </div>
    );

    const frameworkComponents = {
        customerTypeColumn: customerTypeCellRenderer,
        subStartDateColumn: subStartDateCellRenderer,
        subEndDateColumn: subEndDateCellRenderer,
        actionsRenderer: actionsCellRenderer,
    };

    const columnDefs = [
        {
            headerName: 'Customer Name',
            headerTooltip: 'Customer Name',
            field: 'name',
            tooltipField: 'name',
            floatingFilter: true,
            filter: true,
        },
        {
            headerName: 'Customer Type',
            headerTooltip: 'Customer Type',
            field: 'customer_type',
            tooltipField: 'customer_type',
            floatingFilter: true,
            filter: true,
            cellRenderer: 'customerTypeColumn',
        },
        {
            headerName: 'Subscription Start Date',
            headerTooltip: 'Subscription Start Date',
            field: 'subscription_start_date',
            tooltipValueGetter: subStartDateCellRenderer,
            floatingFilter: true,
            cellRenderer: 'subStartDateColumn',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: dateColFilterComparator,
            },
        },
        {
            headerName: 'Subscription End Date',
            headerTooltip: 'Subscription End Date',
            field: 'subscription_end_date',
            tooltipValueGetter: subEndDateCellRenderer,
            sortable: false,
            floatingFilter: true,
            cellRenderer: 'subEndDateColumn',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: dateColFilterComparator,
            },
        },
        {
            headerName: 'No of Assets Subscribed',
            headerTooltip: 'No of Assets Subscribed',
            field: 'asset_count',
            tooltipField: 'asset_count',
            sortable: false,
            floatingFilter: true,
            filter: true,
        },
        {
            headerName: 'No of assets uploaded/created',
            headerTooltip: 'No of assets uploaded/created',
            field: 'num_of_assets_uploaded',
            tooltipField: 'num_of_assets_uploaded',
            sortable: false,
            floatingFilter: true,
            filter: true,
        },
        {
            headerName: 'Account Manager',
            headerTooltip: 'Account Manager',
            field: 'account_manager_name',
            tooltipField: 'account_manager_name',
            floatingFilter: true,
            filter: true,
        },
    ];

    if (role) {
        columnDefs.push({
            headerName: 'Actions',
            field: ' ',
            sortable: false,
            flex: 0.5,
            cellRenderer: 'actionsRenderer',
            width: 80,
            maxWidth: 80,
            cellStyle: { justifyContent: 'center', alignItems: 'baseline' },
        });
    }

    return (
        <>
            <BreadcrumbTrail rootTitle="Customer Management" />
            <div className="spg-w-100 spg-p-md">
                <CustomHeader
                    searchPlaceholder=""
                    handleSearchSubmit={handleTableSearch}
                    customButtonText="NEW CUSTOMER"
                    leftIconIsPlus
                    handleCustomButtonClick={() => history.push('/createCustomer')}
                    isActionButtonVisible={actions.includes(ACTION.ACTION_EDIT)}
                />
                <DataTableGrid
                    className="cli-datatablegrid-min-height"
                    frameworkComponents={frameworkComponents}
                    rowData={filteredCustomers}
                    columnDefs={columnDefs}
                    onRowClicked={(e) => history.push(`/customerDetails/${e.data?.id}`)}
                    pagination
                    loading={!dataLoaded}
                />
            </div>
        </>
    );
};

export default CustomerList;
