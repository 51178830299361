import React, { useState } from 'react';
import {
  Button,
  Icon,
  Select,
  FormGroup,
  useNotification,
  NotificationType,
} from '@spglobal/react-components';
import { useApiGet } from 'hooks/useApiGet';
import { generateReport } from 'services/exportService';
import { getUserDetails } from 'utils/rbacUtils';
import { MULTIPLE_RETURN_PERIOD_OPTIONS } from 'utils/constants';
import { Purpose } from '@spglobal/koi-helpers';
import { EXPORT } from '@spglobal/koi-icons';
import MRPExportSummaryModal from './MRPExportSummaryModal';
import MRPExportLimitDialog from './MRPExportLimitDialog';
import { isEmptyObject } from '../utils/koiIntegrationUtils';
import { CustomerSelect } from '../components/ReportSelects';
import DataService from '../service/data.service';

export default function MRPExportForm({
  selectedCustomer,
  setSelectedCustomer,
  getExportList,
  updateTableState,
  pageData,
}) {
  const [selectedReturnPeriod, setSelectedReturnPeriod] = useState({});

  const mrpOptions = [...MULTIPLE_RETURN_PERIOD_OPTIONS];

  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const { addNotification } = useNotification();
  const [isOpen, setIsOpen] = useState(false);
  const [isRequestFailedWith413, setIsRequestFailedWith413] = useState(false);

  const { data: customers } = useApiGet('api/customers', {});
  const service = new DataService();

  const getMRPSelect = () => {
    const defaultSelectedReturnPeriod = !isEmptyObject(selectedReturnPeriod)
      ? [selectedReturnPeriod]
      : [];

    const handleMRPChange = (values) => {
      const newReturnPeriod = values[0];
      setSelectedReturnPeriod(newReturnPeriod);
    };

    return (
      <FormGroup label="Select Return Period" labelfor="mrp-select" required>
        <Select
          id="mrp-select"
          defaultValue={defaultSelectedReturnPeriod}
          options={mrpOptions}
          onChange={handleMRPChange}
          isSearchable={false}
          isMulti={false}
          closeOnSelection
          placeholder=""
        />
      </FormGroup>
    );
  };

  const validateIsSubmitDisabled = () => {
    let isDisabled = false;
    if (!selectedCustomer?.label || !selectedReturnPeriod?.label) {
      isDisabled = true;
    }

    return isDisabled;
  };

  const handleFormSubmit = async (isConfirm) => {
    if (!isConfirm) {
      setShowSummaryModal(isConfirm);
    } else {
      const { name, email } = getUserDetails();
      const requestParams = {
        customer_name: selectedCustomer?.label.replace(/[/ ]/g, '_'),
        return_period: selectedReturnPeriod?.value,
        report_type: 'mrp_report',
        user_name: name,
        user_email: email,
      };
      try {
        const response = await generateReport(selectedCustomer?.id, requestParams);

        // const result = await getExportList(0, pageData.rowsPerPage);
        // updateTableState(result);
        service.downloadFile(response.pre_signed_url, response.filename, true);

        // Add success notification
        addNotification('Your export is ready for download.', NotificationType.SUCCESS);
      } catch (err) {
        setIsRequestFailedWith413(err?.message === 'Request failed with status code 413');
        addNotification(err?.response?.data || err?.message, NotificationType.ERROR);
      }

      setShowSummaryModal(false);
      setIsOpen(true);
    }
  };

  const dialogParam = {
    handleClose: () => setIsOpen(false),
    open: isOpen,
  };

  return (
    <>
      <>
        <div className="spg-row">
          <div className="spg-col-4">
            <CustomerSelect
              customers={customers}
              selectedCustomer={selectedCustomer}
              setSelectedCustomer={setSelectedCustomer}
              disableSelect
            />
          </div>
          <div className="spg-col-4">{getMRPSelect()}</div>
        </div>

        <div className="spg-d-flex spg-justify-end spg-mt-md spg-mb-md">
          <Button
            type="submit"
            id="generateExportButton"
            leftIcon={<Icon icon={EXPORT} disableAutomaticHoverState />}
            purpose={Purpose.PRIMARY}
            onClick={() => setShowSummaryModal(true)}
            disabled={validateIsSubmitDisabled()}
          >
            GENERATE REPORT
          </Button>
        </div>
      </>

      {isRequestFailedWith413 && <MRPExportLimitDialog {...dialogParam} />}

      {showSummaryModal && (
        <MRPExportSummaryModal
          selectedCustomer={selectedCustomer}
          selectedReturnPeriod={selectedReturnPeriod}
          onConfirm={(value) => handleFormSubmit(value)}
        />
      )}
    </>
  );
}
