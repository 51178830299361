import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {
    Card,
    CardBody,
    Button,
    CardHeader,
    CardFooter,
    Link,H2, H3, Modal, ModalContent, ModalFooter
} from '@spglobal/react-components';
import styled from '@emotion/styled';
import {Size, Classes } from '@spglobal/koi-helpers';
import { useHistory } from 'react-router-dom';
import { TextBodyLg, TextBodyMd } from '../shared';
import IconBand from '../IconBand/IconBand';
import DataService from '../../service/data.service';

const ProductItem = styled.div`
    display: flex;
    border: 1px solid var(--color-base-gray-11);
    border-radius: var(--size-space-sm);
    overflow: hidden;
    padding: 5px;
    vertical-align: middle;
`;

const ProductItemCard = styled(Card)`
    display: inline-block;
    overflow: hidden;
    width: 100%;
    vertical-align: middle;
    height: 100px;
`;

const NoSubscriptionText = styled(TextBodyMd)`
    font-style: italic;
`;

export default function ProductCard({ product }) {
    const [showDialog, setShowDialog] = useState(false);
    const [showlaunchButton, setShowlaunchButton] = useState(false);

    const [isSubscriptionExpired, setIsSubscriptionValid] = useState(false);
    const { oktaAuth } = useOktaAuth();
    const service = new DataService();

    const history = useHistory();

    if (!product) {
        return <div data-testid="no-product"></div>;
    }

    const {
        productName,
        productDescription,
        path,
        isSubscribed,
        details,
        isNew,
        isNewText,
        contact,
        isGreyed,
    } = product;

    const handleClose = () => {
        setShowDialog(false);
        oktaAuth.signOut();
    };


    useEffect(() => {
        (async () => {
          try {
            if (productName === 'Climate Risk'){
                const customersAssigned = await service.getCustomers();

                if (customersAssigned && customersAssigned?.results?.length > 0) {

                    let count = 0;
                    customersAssigned?.results.forEach((customer) => {

                        const subscriptionEndDate = new Date(customer?.subscription_end_date);
                        if (customer?.customer_type?.toLowerCase() === 'active' && subscriptionEndDate && subscriptionEndDate < new Date()) {
                            // customer is active but subscription end date is less than current date
                            count += 1;
                        }
                    });

                    if (count > 0 && count === customersAssigned?.results?.length) {
                        setIsSubscriptionValid(true);
                    }
                }
            }
            // Enable launch button after customer check has been done
            setShowlaunchButton(true);
          } catch (error) {
            console.error('An error occurred in subscription check:', error);
          }
        })();
      }, []);


    useEffect(() => {
        const linkElementClimateRisk = document.getElementById('productClimateRiskContactLinkId');
        const linkElementNatureRisk = document.getElementById('productNatureRiskContactLinkId');

        if (productName === 'Climate Risk' && linkElementClimateRisk)
            linkElementClimateRisk.href = contact;

        if (productName === 'Nature Risk' && linkElementNatureRisk)
            linkElementNatureRisk.href = contact;
    }, []);

    const handleLaunch = (e) => {
        e.preventDefault();
        if (path === '/real-assets') {
            // Store the intended destination in localStorage
            localStorage.setItem('postLoginRedirect', path);
        }

        if (isSubscriptionExpired && path === '/real-assets') {
            setShowDialog(true);
            return;
        }
        // Navigate to the path
        window.open(path, '_blank');
    };

    return (
        <>
            <Modal size={Size.SMALL} aria-labelledby="customized-dialog-title" isOpen={showDialog}>
                <ModalContent>
                    <TextBodyMd className="spg-mt-md">
                        Your subscription to Climanomics has expired. To regain access, please contact your Relationship Manager to renew your
                        subscription. If you believe this is an error or need assistance, please contact Climanomics Support at
                        <a href="mailto:Climanomics_Support@spglobal.com"> Climanomics_Support@spglobal.com</a>.
                    </TextBodyMd>
                    <TextBodyMd>
                        Click Ok to Logout.
                    </TextBodyMd>
                </ModalContent>
                <ModalFooter>
                    <Button purpose="primary" active={true} onClick={handleClose}>
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
            <ProductItem >
                <IconBand iconType={productName} />
                <ProductItemCard hasRoundedCorner>
                    <div className="spg-d-flex spg-justify-between spg-h-500">
                        <div className="spg-d-flex spg-flex-column spg-justify-center">
                            <div className="spg-d-flex">
                                <CardHeader data-testid="product-title" title={productName} />
                                {isNew && (
                                    <span className={`${Classes.LABEL_REQUIRED} spg-ml-xs`}>
                                        {isNewText}
                                    </span>
                                )}
                            </div>
                            <div className="spg-d-flex spg-justify-between">
                                <CardBody>
                                    <TextBodyLg data-testid="product-description">
                                        {productDescription}
                                    </TextBodyLg>
                                </CardBody>
                            </div>
                            <div>
                                {!isSubscribed && (
                                    <CardFooter className="spg-text-right spg-align-top">
                                        <NoSubscriptionText>
                                            This product is available based on subscription. Please{' '}
                                            {productName == 'Climate Risk' && (
                                                <Link id="productClimateRiskContactLinkId" href="">
                                                    contact us
                                                </Link>
                                            )}
                                            {productName == 'Nature Risk' && (
                                                <Link id="productNatureRiskContactLinkId" href="">
                                                    contact us
                                                </Link>
                                            )}{' '}
                                            for trial access.
                                        </NoSubscriptionText>
                                    </CardFooter>
                                )}
                            </div>
                        </div>
                        <div className="spg-d-flex spg-flex-column spg-justify-center">
                            {isSubscribed && (
                                <CardFooter className="spg-text-right spg-align-center">
                                    <Button
                                        purpose="primary"
                                        className="btn-min-width"
                                        disabled={!showlaunchButton}
                                        onClick={handleLaunch}
                                    >
                                        {isSubscribed ? 'Launch' : 'Request Trial'}
                                        <span className="sr-only">{productName}</span>
                                    </Button>
                                </CardFooter>
                            )}
                        </div>
                    </div>
                </ProductItemCard>
            </ProductItem>
        </>
    );
}
