import React from 'react';
import GenerateReportTable from '../components/GenerateReportTable';
import { getLocalDateTime } from '../utils/generateReportUtils';

export default function ExportTable({
    tableData,
    getExportList,
    updateTableState,
    count,
    pageData,
    setPageData,
}) {
    const getFilterCount = (row) => {
        let filtersCount = 0;
        const filters = row?.filters ? row.filters : {};
        Object.keys(filters).forEach((item) => {
            if (item === 'datapoints' && filters[item]?.length) filtersCount++;
            if (item === 'date_range' && filters[item]) filtersCount++;
            if (item === 'decade' && filters[item]?.length) filtersCount++;
            if (item === 'output' && filters[item]) filtersCount++;
            if (item === 'risk_factors' && filters[item]?.length) filtersCount++;
            if (item === 'scenario' && filters[item]?.length) filtersCount++;
        });

        return filtersCount;
    };

    const columnDefs = [
        {
            headerName: 'Request Id',
            field: 'job_id',
            sortable: true,
            tooltipField: 'Request Id',
        },
        {
            headerName: 'File Name',
            field: 'file_name',
            sortable: true,
            tooltipField: 'file_name',
        },
        {
            headerName: 'Filters Used',
            sortable: false,
            cellRenderer: 'filtersRenderer',
            maxWidth: 130,
        },
        {
            headerName: 'Folders',
            valueGetter: ({ data }) => data?.folder_ids.length,
            sortable: false,
            tooltipValueGetter: ({ data }) => data?.folder_names.toString(),
            maxWidth: 130,
        },
        {
            headerName: 'Export By',
            field: 'user_email',
            sortable: true,
            tooltipField: 'user_email',
        },
        {
            headerName: 'Export Date',
            valueGetter: ({ data }) => new Date(data?.created_at).toLocaleDateString(),
            sortable: true,
            tooltipValueGetter: ({ data }) => getLocalDateTime(data),
            maxWidth: 130,
        },
        {
            headerName: 'File Status',
            sortable: false,
            cellRenderer: 'fileStatusRenderer',
            maxWidth: 110,
            cellStyle: { alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        },
        {
            headerName: ' ',
            field: ' ',
            cellRenderer: 'refreshRenderer',
            maxWidth: 50,
            cellStyle: { justifyContent: 'center', alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        },
        {
            headerName: ' ',
            field: ' ',
            cellRenderer: 'retryRenderer',
            maxWidth: 50,
            cellStyle: { justifyContent: 'center', alignItems: 'baseline' },
            cellClass: 'cli-action-cell',
        },
    ];

    return (
        <GenerateReportTable
            tableData={tableData}
            getReportList={getExportList}
            updateTableState={updateTableState}
            count={count}
            pageData={pageData}
            setPageData={setPageData}
            columnDefs={columnDefs}
            getFilterCount={getFilterCount}
            isExportTable
        />
    );
}
